<template>
  <div class="modal__dialog">
    <div class="modal__header">
      <div class="modal__close">
        <img
          src="../../../assets/img/ico/ico-close.svg"
          alt="Cerrar"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="modal__content" v-if="isPopupWindow || popupHasBeenOpened">
      <div class="card-success">
        <div class="card-success__ico">
          <img
            src="../../../assets/img/ico/ico-cover-favicon-success.svg"
            alt="¡Felicidades!"
          />
        </div>
        <h3 class="card-success__header">{{ $t("freeAdd.popupTitleSuccess") }}</h3>
        <div class="card-success__content">
          {{ $t("freeAdd.popupDescriptionSuccess") }}
        </div>
      </div>
    </div>

    <div class="modal__content" v-else>
      <div class="card-success">
        <div class="card-success__ico">
          <img
            src="../../../assets/img/ico/ico-cover-favicon-success.svg"
            alt="¡Felicidades!"
          />
        </div>
        <h3 class="card-success__header">{{ $t("freeAdd.popupTitle") }}</h3>
        <div class="card-success__content">
          {{ $t("freeAdd.popupDescription") }}
        </div>
        <a
          class="button button--primary"
          @click.prevent="$emit('popup')"
        >{{ $t("freeAdd.popupOpen") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PopupExtension",
  props: {
    isPopupWindow: Boolean,
    popupHasBeenOpened: Boolean,
  },
};
</script>
