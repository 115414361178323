<template>
  <div class="modal__dialog">
    <div class="modal__header">
      <div class="modal__close">
        <img
          src="../../../assets/img/ico/ico-close.svg"
          alt="Cerrar"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="modal__content">
      <div class="card-success">
        <div class="card-success__ico">
          <img
            src="../../../assets/img/ico/ico-cover-favicon-success.svg"
            alt="¡Felicidades!"
          />
        </div>
        <h3 class="card-success__header">{{ $t("freeAdd.extensionLoginTitle") }}</h3>
        <div class="card-success__content">
          {{ message }}
        </div>
        <a
          class="button button--primary"
          @click="openLogin"
          href="/login" target="_blank"
          v-show="!loginOpened"
          >{{ $t('freeAdd.extensionLoginButton') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bbButton from '../../../components/buttons/bbButton.vue';

export default {
  components: { bbButton },
  name: "PleaseLoginExtension",
  data() {
    return {
      loginOpened: false,
      message: this.$t("freeAdd.extensionLoginSubtitle")
    };
  },
  computed: {
    subtitle() {
      return "come huevos";
    }
  },
  methods: {
    openLogin() {
      //wait one second and change this.loginOpened to true
      setTimeout(() => {
        this.loginOpened = true;
        this.message = this.$t("freeAdd.extensionLoginSubtitleClicked");
      }, 1000);
    },
  },
};
</script>
