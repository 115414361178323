<template>
  <div class="modal__dialog">
    <div class="modal__header">
      <div class="modal__close">
        <img
          src="../../../assets/img/ico/ico-close.svg"
          alt="Cerrar"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="modal__content">
      <div class="card-success">
        <div class="card-success__ico">
          <img
            src="../../../assets/img/ico/ico-color-success.svg"
            alt="¡Felicidades!"
          />
        </div>
        <h3 class="card-success__header">{{ $t("freeAdd.extensionProductTitle") }}</h3>
        <div class="card-success__content">
          {{ $t("freeAdd.extensionProductSubtitle") }}
        </div>
        <div class="card-success__actions card-success__actions--vertical card-success__actions--50">
          <router-link
            to="/mi-lista"
            class="button button--primary"
            target="_blank"
          >
            {{ $t("freeAdd.extensionProductButton") }}
          </router-link>
          <a
            @click.prevent="$emit('close')"
            href="#"
            class="button button--secondary"
          >
            {{ $t("generic.close") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductAddedToListFromExtension",
};
</script>
