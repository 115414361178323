import Bowser from "bowser";

export default {
  data() {
    return {
      browser: null,
    };
  },
  computed: {
    isFirefox() {
      return this.browser.satisfies({
        firefox: ">=0",
      });
    },
    isChrome() {
      return this.browser.satisfies({
        chrome: ">=0",
        chromium: ">=0",
      });
    },
  },
  beforeMount() {
    this.browser = Bowser.getParser(window.navigator.userAgent);
  },
}
